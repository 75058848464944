import 'promise-polyfill/src/polyfill'
import * as Sentry from '@sentry/browser'
import {currentUser, getMetaContent} from '@/current_user'

let sentryDsn = getMetaContent('sentry-dsn')
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    ignoreErrors:[
      "Cannot read property 'modLayer' of null", // Caused by outdated eChart Lib
      "Object Not Found Matching Id" // Caused by Microsoft email checker which is not able to handle JS
    ]}
  )
  Sentry.configureScope((scope) => {
    scope.setUser({'id': currentUser()})

    window.Sentry = Sentry
  })
}
